<template>
  <div class="video"
       style="">
    <video :loop="loop"
           :autoplay="auto"
           :src="src"
           :muted="muted"
           class="audio"
           style="min-width: 1240px;height:100vh;">
      网红头条-国内领先的直播行业资源社区
    </video>
  </div>
</template>

<script>
export default {
  name: "HomeVide",
  data () {
    return {
      src:
        "https://oss.mcn-open.com/video/8f9efadd70de6243d16248ba57ab31b9.mp4",
      auto: "autoplay",
      loop: "loop",
      muted: "munted"
    };
  },
  props: {
    sendSlience: String
  },
  watch: {
    sendSlience (val) {
      this.muted = val;
      console.log(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.video {
  height: 100vh;
}
.audio {
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
