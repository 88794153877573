<template>
  <div id="myChart"
       :style="{ width: '580px', height: '526px' }"></div>
</template>

<script>
import echarts from 'echarts'
export default {
  name: "HomeHeader",
  data () {
    return {
      echartsData: [
        {
          name: "探探签约主播",
          value: 25
        },
        {
          name: "快手签约主播",
          value: 20
        },
        {
          name: "抖音签约主播",
          value: 18
        },
        {
          name: "平台经纪人",
          value: 15
        },
        {
          name: "线下网红基地",
          value: 8
        }
      ]
    };
  },
  mounted () {
    this.drawLine();
  },
  methods: {
    drawLine () {
      var colorList = [
        {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(51,192,205,0.01)" // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(51,192,205,0.57)" // 100% 处的颜色
            }
          ],
          globalCoord: false // 缺省为 false
        },
        {
          type: "linear",
          x: 1,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(115,172,255,0.02)" // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(115,172,255,0.67)" // 100% 处的颜色
            }
          ],
          globalCoord: false // 缺省为 false
        },
        {
          type: "linear",
          x: 1,
          y: 0,
          x2: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "rgba(158,135,255,0.02)" // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(158,135,255,0.57)" // 100% 处的颜色
            }
          ],
          globalCoord: false // 缺省为 false
        },
        {
          type: "linear",
          x: 0,
          y: 1,
          x2: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "rgba(252,75,75,0.01)" // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(252,75,75,0.57)" // 100% 处的颜色
            }
          ],
          globalCoord: false // 缺省为 false
        },
        {
          type: "linear",
          x: 1,
          y: 1,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "rgba(253,138,106,0.01)" // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#FDB36ac2" // 100% 处的颜色
            }
          ],
          globalCoord: false // 缺省为 false
        },
        {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "rgba(254,206,67,0.12)" // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#FECE4391" // 100% 处的颜色
            }
          ],
          globalCoord: false // 缺省为 false
        }
      ];
      var colorLine = [
        "#33C0CD",
        "#73ACFF",
        "#9E87FF",
        "#FE6969",
        "#FDB36A",
        "#FECE43"
      ];

      function getRich () {
        let result = {};
        colorLine.forEach((v, i) => {
          result[`hr${i}`] = {
            backgroundColor: colorLine[i],
            borderRadius: 3,
            width: 3,
            height: 3,
            padding: [3, 3, 0, -12]
          };
          result[`a${i}`] = {
            padding: [8, -70, -20, -50],
            color: colorLine[i],
            fontSize: 12
          };
        });
        return result;
      }
      let data = this.echartsData.sort((a, b) => {
        return b.value - a.value;
      });
      data.forEach((v, i) => {
        v.labelLine = {
          lineStyle: {
            width: 1,
            color: colorLine[i]
          }
        };
      });
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        series: [
          {
            type: "pie",
            radius: "76%",
            center: ["50%", "50%"],
            clockwise: true,
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: "outside",
              formatter: function (params) {
                const name = params.name;
                const percent = params.percent + "%";
                const index = params.dataIndex;
                return [
                  `{a${index}|${name}：${percent}}`,
                  `{hr${index}|}`
                ].join("\n");
              },
              rich: getRich()
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                }
              }
            },
            data,
            roseType: "radius"
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
