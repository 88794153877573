<template>
  <div class="box-w">
    <div class="box">
      <img src="@/static/wanghong/siluogen.png"
           alt="国内领先的直播行业资源社区"
           class="title" />
      <div class="btnGroup">
        <div class="btn-c hover"
             @click="onClick">
          <span class="iconfont iconiphone"></span>
          <span>AppStore</span>
        </div>
        <div class="btn-c hover"
             @click="onClick">
          <span class="iconfont iconanzhuo"></span>
          <span>Android</span>
        </div>
        <el-popover placement="top"
                    ref="popover"
                    trigger="hover">
          <qrcode-vue :value="href"
                      :size="150"
                      level="H"></qrcode-vue>
        </el-popover>
        <div class="btn-d hover"
             v-popover:popover>
          <span class="iconfont iconerweima"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
export default {
  name: "HomeContent",
  components: {
    QrcodeVue
  },
  data () {
    return {
      href: "http://fir.wanghongtoutiao.cn/whtt"
    };
  },
  methods: {
    onClick () {
      window.open(this.href)
    }
  }
};
</script>

<style lang="scss" scoped>
.box-w {
  width: 100%;
  height: 100vh;
  margin-top: -100vh;
  position: relative;
}
.box {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .title {
    margin-top: 120px;
  }
  .btnGroup {
    width: 355px;
    height: 45px;
    margin-top: 198px;
    display: flex;
    justify-content: space-between;
    .btn-c,
    .btn-d {
      height: 45px;
      border: 1px solid #fff;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      color: #fff;
      background: rgba(0, 0, 0, 0.3);
      transition: all 0.4s;
      .iconfont {
        font-size: 30px;
      }
      &:hover {
        color: #666;
      }
    }
    .btn-c {
      width: 133px;
      .iconfont {
        margin-right: 7px;
      }
    }
    .btn-d {
      width: 45px;
    }
  }
}
</style>
