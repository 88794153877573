<template>
  <div class="software">
    <div class="softwareBox">
      软件应用
      <div class="titleTextEn">
        SOFTWARE APPLICATION
      </div>
    </div>
    <div class="imgBox">
      <img class="leftImg" src="@/static/img/home/semicircle.png" alt="" />
      <img class="rightImg" src="@/static/img/home/phone.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "software",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.software {
  height: 844px;
  width: 100%;
  background-image: url("/@/static/img/home/silkBg.png");
  // background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 75%;
  background: #f9f9f9;
}
.softwareBox {
  width: 100%;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 48px;
  font-size: 28px;
  font-weight: 500;
  color: #333333;
  position: relative;
  .titleTextEn {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.1);
  }
}
.imgBox {
  position: relative;
  width: 1240px;
  margin: 0 auto;
  .leftImg {
    position: absolute;
    top: 7px;
    left: 9%;
  }
  .rightImg {
    position: absolute;
    top: 25px;
    right: 10%;
  }
}
</style>
